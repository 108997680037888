import React from 'react';
import { createStyles, Text, Container, Image, Center } from '@mantine/core';
import { IconPhoneCall, IconMapPin } from '@tabler/icons';

/** 页面底部组件属性 */
type TFooterLinksProps = {
    /** 底部组件菜单列表 */
    data: {
        /** 菜单项标题 */
        title: string;
        /** 链接列表 */
        links: Array<{
            /** 链接标题 */
            label: string;
            /** 链接地址 */
            link: string;
        }>;
    }[];
}

/** 声明页面底部组件样式 */
const useStyles = createStyles((theme) => ({
    footer: {
        paddingTop: theme.spacing.xl * 2,
        paddingBottom: theme.spacing.xl * 2,
        borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]}`
    },

    logo: {
        maxWidth: 258,
        [theme.fn.largerThan('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        [theme.fn.smallerThan('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
    },

    description: {
        marginTop: 10,

        [theme.fn.smallerThan('sm')]: {
            marginTop: theme.spacing.xs,
            textAlign: 'center'
        },
    },

    contact: {
        marginTop: 20,
        alignItems: "center",
        [theme.fn.smallerThan('sm')]: {
            display: 'none'
        },
    },

    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: 1440,
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            alignItems: 'center'
        },
    },

    groups: {
        display: 'flex',
        flexWrap: 'wrap',

        [theme.fn.smallerThan('sm')]: {
            display: 'none'
        },
    },

    wrapper: {
        width: 160
    },

    link: {
        display: 'block',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
        fontSize: theme.fontSizes.sm,
        paddingTop: 3,
        paddingBottom: 3,

        '&:hover': {
            textDecoration: 'underline'
        },
    },

    title: {
        fontSize: theme.fontSizes.lg,
        fontWeight: 700,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        marginBottom: theme.spacing.xs / 2,
        color: theme.colorScheme === 'dark' ? theme.white : theme.black
    },

    afterFooter: {
        maxWidth: 1440,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing.xl,
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl,
        borderTop: `1px solid ${theme.colors.gray[2]}`,

        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            gap: theme.spacing.sm
        },
    },

    social: {
        [theme.fn.smallerThan('sm')]: {
            marginTop: theme.spacing.xs
        },
    },
    govHref: {
        textDecoration: "none",
        color: "#868e96",
        "&:hover": {
            textDecoration: "underline"
        }
    }
}));



const Footer = ({ data }: TFooterLinksProps) => {
    /** 引入声明的属性 */
    const { classes } = useStyles();

    /** 将接收到的数据渲染成菜单 */
    const groups = data.map((group) => {
        const links = group.links.map((link, index) => (
            <Text<'a'>
                key={index}
                className={classes.link}
                component="a"
                href={link.link}>
                {link.label}
            </Text>
        ));

        return (
            <div className={classes.wrapper} key={group.title}>
                <Text className={classes.title}>{group.title}</Text>
                {links}
            </div>
        );
    });

    return (
        <footer className={classes.footer}>
            <Container className={classes.inner}>
                <div className={classes.logo}>
                    <Image
                        width={160}
                        height={28}
                        radius='md'
                        src="https://spu.qhulian.com/material/logo_light.png"
                        alt="qhulian logo"
                    />
                    <Text size="xs" color="dimmed" className={classes.description}>
                        值得信赖的企业信息化管家
                    </Text>
                    <Text size="xs" color="dimmed" className={classes.contact}>
                        <Center>
                            <IconPhoneCall size={16} />
                            &nbsp;咨询电话：189-2678-5103
                        </Center>
                    </Text>
                    <Text size="xs" color="dimmed" className={classes.contact}>
                        <Center sx={{ alignItems: "flex-start" }}>
                            <div style={{ width: 16, height: 16 }}>
                                <IconMapPin size={16} />
                            </div>
                            &nbsp;公司地址：深圳市龙华区大浪街道上横朗社区福龙路旁恒大时尚慧谷大厦（东区）6栋314
                        </Center>
                    </Text>
                </div>
                <div className={classes.groups}>{groups}</div>
            </Container>
            <Container className={classes.afterFooter}>
                <Text color="dimmed" size="sm" style={{ textAlign: 'center' }}>
                    Copyright © 2015 - {new Date().getFullYear()} Shenzhen QingHuLian Technology CO.,Ltd. All Rights Reserved.
                </Text>

                <Text color="dimmed" size="sm">
                    深圳市轻互联科技版权所有 - <a href='https://beian.miit.gov.cn/' className={classes.govHref}>粤ICP备10104306号-4</a>
                </Text>
            </Container>
        </footer>
    );

}

export default Footer;